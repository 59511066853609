<template>
    <row v-if="done">
      <col6>
        <v-card class="elevation-4">
            <v-card-title>
                {{$t('annotate.no_ann_title')}}
            </v-card-title>
            <v-card-subtitle style="min-height: 60px;">
                {{$t('annotate.no_ann_subtitle')}}
            </v-card-subtitle>
           <v-card-text>
              <v-img :src="api_url + '/digger.png'"></v-img>
           </v-card-text>
        </v-card>
      </col6>

    </row>
    <v-row v-else>
      <col8>
        <loader :loading="loading"></loader>
        <div v-if="annotationLimitReached">
          <show-alert :alert-message="annotationLimitReachedMessage"></show-alert>
        </div>
        <div id="dummy" tabindex="-1" style="width: 0; height: 0; overflow: hidden;"></div>
        <!-- @canvas_mousedown="canvas_mousedown" -->
        <!-- :is_embedding_loading="isEmbeddingLoading" -->
        <annotated-image
            @image_loaded="refresh_annotations"
            :zoom_enabled="true"
            :image_url="asset_url"
            :annotations="annotations"
            @update_coords="updateAnnotation"
            @delete_annotation="deleteAnnotation"
            @select="selectAnnotation"
            @unselect="unselectAnnotation"
            @add_annotation_deselect_others="add_annotation_deselect_others"
            :contrast="contrast"
            :brightness="brightness"
            @image_url_with_token="handle_image_url_with_token"
        ></annotated-image>
        <meta-grid :meta="meta"></meta-grid>
      </col8>
      <col3 class="col-md-3 col-lg-2">
        <row class="row">
          <col3 class="col-md-3">
            {{$t('annotate.brightness')}}
          </col3>
          <col9 class="col-md-9">
            <input type="range" id="brightness" v-model="brightness" min="-1" max="1" step="0.003921">
          </col9>
        </row>
        <row class="row">
          <col3 class="col-md-3">
            {{$t('annotate.contrast')}}
          </col3>
          <col9 class="col-md-9">
            <input type="range" id="contrast" v-model="contrast" min="-1" max="1" step="0.003921">
          </col9>
        </row>

        <row class="row">
          <col12 class="col-md-12">
            <hr/>
          </col12>
        </row>

        <row class="row">
          <col12 class="col-md-12">
            <annotate-labels :disabled="annotationLimitReached" @add_annotation="addAnnotationClearSelectionAndClearFocus" :type="'box'"></annotate-labels>
            <annotate-labels :disabled="annotationLimitReached" @add_annotation="addAnnotationClearSelectionAndClearFocus" :type="'point'"></annotate-labels>
            <annotate-label-groups @add_annotation="addAnnotationClearSelectionAndClearFocus"
                                   :type="'group'"></annotate-label-groups>
          </col12>
        </row>

        <row class="row">
          <col12 class="col-md-12">
            <hr/>
          </col12>
        </row>

        <row class="row">
          <col12 class="col-md-12">
            <v-btn v-if="hasAnnotationSuggestions && show_annotation_suggestions" @click="show_suggestions" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="annotation_suggestion" class="button">
              {{$t('annotate.btn_show')}}
            </v-btn>
            <v-btn v-if="!show_annotation_suggestions" @click="hide_suggestions" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="annotation_suggestion" class="button">
              {{$t('annotate.btn_hide')}}
            </v-btn>
            <v-btn v-if="hasEncodedBoundingBoxes && !show_bounding_box_meta" @click="show_bbox_meta" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="show_bounding_box_meta" class="button">
              {{$t('annotate.btn_show_meta')}}
            </v-btn>
            <v-btn v-if="show_bounding_box_meta" @click="hide_bbox_meta" color="primary" style="float:none; margin-left:0px; margin-top: 15px" id="hide_bounding_box_meta" class="button">
              {{$t('annotate.btn_hide_meta')}}
            </v-btn>
            <annotations-list
                @update_label="updateAnnotationLabel"
                @clear="clearAnnotations"
                @select="selectAnnotation"
                @unselect="unselectAnnotation"
                :annotations="annotations"></annotations-list>
          </col12>
        </row>

        <row class="row">
          <col12 class="col-md-12">
            <hr/>
          </col12>
        </row>

        <div v-if="original_category" style="margin-top: 10px">
          Original category: <br/>
          {{ original_category }}
        </div>

        <annotate-buttons
            @set_loading="set_loading"
            :asset_id="asset_id"
            :annotations="annotations"
            :asset_id_history="asset_id_history"
            @stored="next"
            @annotate-limit-reached="handleAnnotationLimitReached"
        ></annotate-buttons>
      </col3>
    </v-row>
</template>

<script>

import AnnotatedImage from "@/components/AnnotatedImage";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import Annotation from "@/classes/annotation";
import AnnotationsList from "@/components/AnnotationsList";
import AnnotateLabels from "@/components/AnnotateLabels";
import AnnotateButtons from "@/components/AnnotateButtons";
import AnnotateLabelGroups from "@/components/AnnotateLabelGroups";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import Col6 from "@/components/layout/Col6";
import Col3 from "@/components/layout/Col3";
import Col9 from "@/components/layout/Col9";
import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
import Col8 from "@/components/layout/Col8";
const { v4: uuidV4 } = require('uuid');
import ShowAlert from "@/components/ShowAlert.vue";
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";
import Loader from "@/components/layout/loader";
import MetaGrid from "@/components/MetaGrid";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
// import SegAnyMixin from "@/mixins/SegAnyMixin";


export default {
  name: "Annotate",
  components: {
    Col8,
    Col9,
    Col3,
    Col6,
    Col12,
    Row,
    AnnotateLabelGroups, Loader, AnnotateButtons, AnnotateLabels, AnnotationsList, AnnotatedImage, ShowAlert, MetaGrid
  },
  mixins: [AnnotateMixin, LoadSettingsMixin, LoadProjectsMixin, CurrentUserMixin, LoadLabelsMixin],
  data() {
    return {
      'loading': true,
      'canvas_id': 'custom_id',
      'find_meta_contains': '',
      'find_original_category': '',
      'original_category': '',
      'meta': '',
      'asset_id': false,
      'asset_id_history': [],
      'asset_url': false,
      'brightness': 0,
      'contrast': 0,
      'show_filter': false,
      'max_results': false,
      'done': false,
      'show_annotation_suggestions': true,
      'show_bounding_box_meta' : false,
      'annotationLimitReached': false,
      'show_meta': false,
    }
  },
  mounted() {
    this.next();
  },
  props: {
    'filters': {required: true},
    'processing_tasks': {required: false, default: false},
  },
  computed: {
    annotationLimitReachedMessage() {
      return this.getLimitMessage('annotate', 'ANNOTATION_LIMIT');
    },
    contact_email() {
      return 'Contact email'//TODO$('#contact_email').val();
    },
    api_url () {
      return this.$api_url;
    },
    hasAnnotationSuggestions() {
      return this.annotation_suggestions.length > 0;
    },
  },
  destroyed() {
  },
  watch: {
    filters() {
      this.next();
    }
  },
  methods: {
    handle_image_url_with_token(url) {
      this.$set(this, 'image_url_with_token', url)
    },
    handleAnnotationLimitReached() {
      this.$set(this, 'annotationLimitReached', true)
    },
    async getUserAnnotationsCount() {
      const response = await this.$api.annotation.filter([], 1000000);
      if (response) {
        return response.items.length;
      }
    },
    set_loading(loading) {
      this.$set(this, 'loading', loading);
    },
    refresh_annotations() {
      this.$set(this, 'annotations', this.annotations)
    },
    updateFilterData(filter_data) {
      this.$set(this, 'filter_data', filter_data)
    },
    refresh() {
      this.next();
    },
    async next() {
      let this2 = this;
      this2.$set(this2, 'done', false)
      this2.$set(this2, 'brightness', 0);
      this2.$set(this2, 'contrast', 0);

      if (this.asset_id) {
        this.asset_id_history.push(this.asset_id);
      }

      let filters = JSON.stringify([{'type': 9, 'value': 'No'}])
      if(this.filters) {
        filters = this.filters;
      }

      let result = await this.$api.asset.next_asset(filters);

      if (result.done) {
        
        this2.$emit('update_progress', 0)

        if(!this.processing_tasks) {
          this2.$set(this2, 'done', true)
          this2.$set(this2, 'loading', false)
          return;
        }
        return;
      }

      this2.$emit('update_progress', result.max_results)

      this2.asset_id = result.asset.id;
      let url = this.$api_host + '/protected/asset/' + result.asset.id;
      let annotations = result.annotations
      this.$set(this, 'annotation_suggestions', result.asset.annotation_suggestions)

      let i = 0;
      annotations = annotations.map(annotation => {
        let id = annotation.id;
        let color = result.colors[i];
        i += 1;
        let type = 'box'
        if (annotation.color) {
          color = annotation.color.split(',')
        }
        if (annotation.type === 2) {
          type = 'point'
        }
        return new Annotation(id, type, annotation.coords, annotation.conf, annotation.label, color);
      });

      this2.$set(this2, 'annotations', [])
      this2.$set(this2, 'asset_url', url)
      this2.$set(this2, 'meta', result.asset.meta)
      this2.$set(this2, 'original_category', result.asset.original_category)

      this2.$set(this2, 'annotations', annotations)
      this2.$set(this2, 'initial_annotations', JSON.parse(JSON.stringify(annotations)))

      if(this.hasAnnotationSuggestions) {
        this.show_suggestions();
      }

      this2.loading = false;

    },
    addAnnotationClearSelectionAndClearFocus(annotation) {
      this.addAnnotationClearSelection(annotation);
      // set focus on the main page
      document.getElementById('dummy').focus();
    },
    show_suggestions() {
      this.annotation_suggestions.forEach(annotation_suggestion => {
        let suggestion = JSON.parse(annotation_suggestion.suggestion)
        if(suggestion.action === 'add_bounding_box') {
          let suggested_label_id = suggestion.label_id
          let suggested_label_name = this.labels.find(label => label.id == suggested_label_id).name
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'box', JSON.parse(suggestion.coords), suggestion.confidence, suggested_label_name, color);
          this.addAnnotationClearSelectionAndClearFocus(annotation)
        }
        else if(suggestion.action === 'add_keypoint') {
          let suggested_label_id = suggestion.label_id
          let suggested_label_name = this.labels.find(label => label.id == suggested_label_id).name
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'point', JSON.parse(suggestion.coords), suggestion.confidence, suggested_label_name, color);
          this.addAnnotationClearSelectionAndClearFocus(annotation)
        }
        else if(suggestion.action === 'delete_annotation') {
          let annotations = this.annotations.filter(annotation => annotation.id !== annotation_suggestion.annotation_id)
          this.$set(this, 'annotations', annotations)
        }
        else if(suggestion.action === 'rename_label_annotation') {
          let suggested_label_id = suggestion.label_id
          let suggested_label_name = this.labels.find(label => label.id === suggested_label_id).name
          let annotation_ids = this.annotations.map(annotation => annotation.id)
          let index = annotation_ids.indexOf(annotation_suggestion.annotation_id)
          this.annotations[index].label = suggested_label_name
        }
      });
      this.show_annotation_suggestions = false;
    },
    hide_suggestions() {
      this.$set(this, 'annotations', JSON.parse(JSON.stringify(this.initial_annotations)));
      this.show_annotation_suggestions = true;
    },
    hide_bbox_meta() {
      this.$set(this, 'annotations', JSON.parse(JSON.stringify(this.initial_annotations)));
      this.show_bounding_box_meta = false;
    },
  }
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
  display: inline-block;
}

.mask-overlay {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none; 
}
</style>
