export default class Models {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async activate(model_id) {
        let formData = new FormData();
        formData.append('model_id', JSON.stringify(model_id));
        return this.api.post_with_token('/activate-model', formData)
    }

    async deactivate(model_id) {
        let formData = new FormData();
        formData.append('model_id', JSON.stringify(model_id));
        return this.api.post_with_token('/deactivate-model', formData)
    }

    async add(saved_filename, name) {
        let formData = new FormData;
        formData.set('saved_filename', saved_filename);
        formData.set('name', name);
        formData.set('project_id', sessionStorage.project_id)
        return this.api.post_with_token('/add-model', formData)
    }

    async delete(model) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('model_id', JSON.stringify(model.id));
        return this.api.post_with_token('/model/delete', formData)
    }

    async all(type) {
        let formData = new FormData();
        if(!type) {
            type = 'yolo5,mobilenet_large,deeplabcut'
        }
        formData.append('type', type);
        let project_id = sessionStorage.getItem('project_id');
        formData.append('project_id', project_id);
        if(project_id == 'false' || project_id==false) {
            return []
        }
        return this.api.post_with_token('/models', formData)
    }

    async get_model_preds(asset_id, model_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('asset_id', asset_id);
        formData.append('model_id', model_id);
        return this.api.post_with_token('/preds', formData)
    }

    async calculate_confusion_matrix(model_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('model_id', model_id);
        return this.api.post_with_token('/conf-matrix-items/calculate', formData)
    }
}